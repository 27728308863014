import React from 'react'
import {Auth0Provider} from '@auth0/auth0-react'
import {navigate} from 'gatsby'

export const onClientEntry = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }
}

console.log('test')

const AuthProvider = ({children}) => {
  return (
    <Auth0Provider
      domain={process.env.NEXT_PUBLIC_AUTH0_ISSUER_DOMAIN}
      clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        redirect_uri: `${process.env.NEXT_PUBLIC_AUTH0_BASE_URL}`,
      }}
      issuer={process.env.NEXT_PUBLIC_AUTH0_ISSUER}
      cookieDomain={process.env.NEXT_PUBLIC_AUTH0_COOKIE_DOMAIN}
      cacheLocation='localstorage'
      onRedirectCallback={appState => {
        if (appState?.returnTo) navigate(appState.returnTo, {replace: true})
      }}
    >
      {children}
    </Auth0Provider>
  )
}

export const wrapRootElement = ({element}) => (
  <AuthProvider>{element}</AuthProvider>
)
